<template>
    <el-container>
        <el-header>
            <h1>Kebijakan Privasi</h1>
        </el-header>
        <el-main>
            <el-card>
                <h2>Membuat Kebijakan Privasi untuk Pendaftaran PPAI Darun Najah</h2>
                <p>Kebijakan privasi adalah dokumen penting yang menjelaskan bagaimana Anda mengumpulkan, menggunakan,
                    dan melindungi data pribadi pengguna. Saat membuat kebijakan privasi untuk pendaftaran santri
                    online, pastikan untuk mencakup aspek-aspek berikut:</p>
                <ul>
                    <li>Data yang dikumpulkan: Jenis data apa saja yang akan dikumpulkan (nama, alamat, nomor telepon,
                        email, dll.)</li>
                    <li>Tujuan penggunaan data: Untuk apa data tersebut digunakan (proses pendaftaran, komunikasi,
                        statistik, dll.)</li>
                    <li>Penyimpanan data: Bagaimana data disimpan dan dijamin keamanannya</li>
                    <li>Pengungkapan data: Kepada siapa data dapat diungkapkan (pihak ketiga, lembaga terkait)</li>
                    <li>Hak pengguna: Hak pengguna atas data pribadi mereka (akses, koreksi, penghapusan)</li>
                    <li>Keamanan data: Langkah-langkah yang diambil untuk melindungi data dari akses yang tidak sah</li>
                    <li>Perubahan kebijakan: Bagaimana kebijakan ini akan diperbarui dan diberitahukan kepada pengguna
                    </li>
                </ul>
                <h3>Pendahuluan</h3>
                <p>Kebijakan Privasi ini menjelaskan bagaimana PPAI Darun Najah mengumpulkan, menggunakan, dan
                    melindungi informasi pribadi yang Anda berikan saat mendaftar sebagai santri melalui situs web kami.
                    Dengan menggunakan layanan kami, Anda menyetujui praktik-praktik yang dijelaskan dalam Kebijakan
                    Privasi ini.</p>
                <h3>Data yang Kami Kumpulkan</h3>
                <p>Kami dapat mengumpulkan data pribadi Anda, termasuk namun tidak terbatas pada:</p>
                <ul>
                    <li>Nama lengkap</li>
                    <li>Alamat email</li>
                    <li>Nomor telepon</li>
                    <li>Alamat rumah</li>
                    <li>Tanggal lahir</li>
                    <li>Informasi pendidikan</li>
                    <li>Semua data yang dibutuhkan dalam pendidikan (tercantum dalam form pendaftaran)</li>
                    <li>Data yang diperoleh melalui login Google OAuth (nama akun Google, email Google)</li>
                </ul>
                <h3>Tujuan Penggunaan Data</h3>
                <p>Kami menggunakan data pribadi Anda untuk:</p>
                <ul>
                    <li>Memproses pendaftaran santri</li>
                    <li>Mengelola komunikasi dengan Anda</li>
                    <li>Menyediakan layanan yang Anda minta</li>
                    <li>Menganalisis data untuk meningkatkan layanan kami</li>
                    <li>Memenuhi persyaratan hukum dan peraturan yang berlaku</li>
                </ul>
                <h3>Penyimpanan Data</h3>
                <p>Data pribadi Anda disimpan di server yang aman dan dilindungi oleh firewall dan langkah-langkah
                    keamanan lainnya. Kami mengambil langkah-langkah yang wajar untuk melindungi data Anda dari akses,
                    penggunaan, pengungkapan, perubahan, atau penghancuran yang tidak sah.</p>
                <h3>Pengungkapan Data</h3>
                <p>Kami dapat mengungkapkan data pribadi Anda kepada:</p>
                <ul>
                    <li>Pihak ketiga yang terpercaya: Kami dapat bekerja sama dengan pihak ketiga yang terpercaya untuk
                        menyediakan layanan tertentu (misalnya, penyedia pembayaran).</li>
                    <li>Lembaga terkait: Kami dapat mengungkapkan data Anda jika diwajibkan oleh hukum atau untuk
                        melindungi hak-hak kami.</li>
                </ul>
                <h3>Hak Pengguna</h3>
                <p>Anda memiliki hak untuk:</p>
                <ul>
                    <li>Mengakses data pribadi Anda</li>
                    <li>Menguji keakuratan data pribadi Anda</li>
                    <li>Memperbarui data pribadi Anda yang tidak akurat atau tidak lengkap</li>
                    <li>Menghapus data pribadi Anda</li>
                </ul>
                <h3>Keamanan Data</h3>
                <p>Kami menerapkan langkah-langkah keamanan yang wajar untuk melindungi data pribadi Anda dari akses,
                    penggunaan, pengungkapan, perubahan, atau penghancuran yang tidak sah. Namun, tidak ada sistem
                    keamanan yang sepenuhnya kebal.</p>
                <h3>Perubahan Kebijakan</h3>
                <p>Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Perubahan yang signifikan akan
                    diberitahukan kepada Anda melalui email atau pemberitahuan di situs web kami.</p>
                <h3>Login Google OAuth</h3>
                <p>Dengan menggunakan login Google OAuth, Anda mengizinkan kami untuk mengakses informasi profil Google
                    Anda yang relevan, seperti nama, alamat email, dan foto profil. Informasi ini akan digunakan untuk
                    mempermudah proses pendaftaran dan verifikasi identitas Anda. Anda dapat mengelola izin akses Google
                    Anda kapan saja melalui pengaturan akun Google Anda.</p>
                <h3>Kontak</h3>
                <p>Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, silakan hubungi kami di
                    ppaidarunnajah.id@gmail.com atau 081805074204.</p>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>

<style scoped>
h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

h2,
h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

p,
ul {
    font-size: 16px;
    line-height: 1.6;
}
</style>